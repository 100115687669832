import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

const darkRed = "#e72e36";

const StyledContainer = styled.div`
    background-color:#FFFFFF;
    margin-top: 40px;
    padding: 0 5px 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 100%;
`;
const StyledItem = styled.div`
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(96, 96, 96);
    border-bottom: solid transparent 1px;
    &:hover {
        color: #000000;
        cursor: pointer;
        border-bottom: solid ${darkRed} 1px;
    }
`;
const styleTitle = { color: darkRed };

export default function Categories() {
    const history = useHistory();
    const location = useLocation();
    const categories = useSelector(({ pos }) => pos.categories);
    const [selected, setSelected] = useState(-1);
    const [level, setLevel] = useState(-1);

    const handleClick = ({ id, name }) => () => {
        history.push({
            pathname: "/",
            search: `?category=${id}&name=${name}`,
        });
    };
    const handleParent = (index) => () => {
        setSelected(index);
    };
    const handleLevel = (index, item) => () => {
        setLevel(index);
        if (index !== -1) {
            history.push({
                pathname: "/",
                search: `?category=${item.id}&name=${item.name}`,
            });
        }
    };
    const resetSelected = () => {
        setSelected(-1);
        setLevel(-1);
        history.push({
            pathname: "/",
            search: ``,
        });
    };
    const resetLevel = () => {
        const item = categories[selected].children[level];
        setLevel(-1);
        history.push({
            pathname: "/",
            search: `?category=${item.id}&name=${item.name}`,
        });
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setSelected(-1);
        setLevel(-1);
        if (urlParams.has("category") !== "") {
            const categoryId = parseInt(urlParams.get("category"));
            let index = categories.findIndex((it) => it.id === categoryId);
            if (index === -1) {
                let indexSec = -1;
                categories.forEach((it, ind1) => {
                    if (indexSec === -1) {
                        const indexTemp = it.children.findIndex(
                            (it2) => it2.id === categoryId
                        );
                        if (indexTemp !== -1) {
                            index = ind1;
                            indexSec = indexTemp;
                        }
                    }
                });
                if (index === -1) {
                    categories.forEach((it, ind1) => {
                        if (indexSec === -1) {
                            it.children.forEach((it2, ind2) => {
                                const indexTemp = it2.children.findIndex(
                                    (it3) => it3.id === categoryId
                                );
                                if (indexTemp !== -1) {
                                    index = ind1;
                                    indexSec = ind2;
                                }
                            });
                        }
                    });
                }
                setSelected(index);
                setLevel(indexSec);
            } else {
                setSelected(index);
            }
        }
    }, [location.search]);

    if (selected !== -1) {
        if (level === -1) {
            const mainCategory = categories[selected];
            return (
                <StyledContainer>
                    <StyledItem onClick={resetSelected}>
                        <i className="fa fa-backward" /> Regresar
                    </StyledItem>
                    <StyledItem style={styleTitle}>
                        <i className="fa fa-circle" /> {mainCategory.name}
                    </StyledItem>
                    {mainCategory.children.map((item, index) => {
                        return (
                            <StyledItem
                                onClick={handleLevel(index, item)}
                                key={item.id}
                            >
                                {"  "}
                                <i className="fa fa-arrow-right" /> {item.name}
                            </StyledItem>
                        );
                    })}
                </StyledContainer>
            );
        } else {
            const mainCategory = categories[selected];
            const secondCategory = mainCategory.children[level];
            return (
                <StyledContainer>
                    <StyledItem onClick={resetLevel}>
                        <i className="fa fa-backward" /> Regresar
                    </StyledItem>
                    <StyledItem style={styleTitle}>
                        <i className="fa fa-circle" /> {secondCategory.name}
                    </StyledItem>
                    {secondCategory.children.map((item, index) => {
                        return (
                            <StyledItem
                                onClick={handleClick(item)}
                                key={item.id}
                            >
                                {"  "}
                                <i className="fa fa-arrow-right" /> {item.name}
                            </StyledItem>
                        );
                    })}
                </StyledContainer>
            );
        }
    } else {
        return (
            <StyledContainer>
                {categories.map((item, index) => {
                    return (
                        <StyledItem onClick={handleParent(index)} key={item.id}>
                            {item.name}
                        </StyledItem>
                    );
                })}
            </StyledContainer>
        );
    }
}
