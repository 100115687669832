import React from "react";
import { Button, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import * as Actions from "../store/actions/cart";
import useWindowSize from "../hooks/useWindowSize";

import { QtySelect } from "../styles/components";
import priceFormat from "../utils/priceFormat";

export default function CartDetail({ modal }) {
    const dispatch = useDispatch();
    const cart = useSelector(({ cart }) => cart.items);

    const windowSize = useWindowSize();

    const deleteItem = (index) => () => {
        dispatch(Actions.removeRow(index));
    };
    const addQty = (index, qty) => () => {
        dispatch(Actions.setQty(index, cart[index].qty + qty));
    };

    let imgStyle = {};
    if (modal) imgStyle = { width: "50px" };

    return (
        <Table striped hover>
            <tbody>
                <tr>
                    {windowSize === "sm" ? (
                        <th style={{ textAlign: "center" }}>Detalle</th>
                    ) : (
                        <>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Total</th>
                            <th></th>
                        </>
                    )}
                </tr>
                {cart.map((swag, index) => (
                    <tr key={swag.productId}>
                        {windowSize === "sm" ? (
                            <td>
                                <div
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <strong
                                        style={{
                                            color: "green",
                                        }}
                                    >
                                        USD {priceFormat(swag.price)}
                                    </strong>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <img
                                            src={swag.img}
                                            alt={swag.name}
                                            style={imgStyle}
                                        />{" "}
                                    </div>
                                    <div className="col-8">{swag.name}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <span>
                                            <QtySelect>
                                                <button
                                                    onClick={addQty(index, -1)}
                                                >
                                                    -
                                                </button>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={swag.qty}
                                                />
                                                <button
                                                    onClick={addQty(index, 1)}
                                                >
                                                    +
                                                </button>
                                            </QtySelect>
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <span
                                            style={{
                                                color: "green",
                                            }}
                                        >
                                            Total{" "}
                                            {priceFormat(swag.qty * swag.price)}
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            variant="danger"
                                            onClick={deleteItem(index)}
                                        >
                                            <i className="fa fa-trash-o" />
                                        </Button>
                                    </div>
                                </div>
                            </td>
                        ) : (
                            <>
                                <td>
                                    <img
                                        src={swag.img}
                                        alt={swag.name}
                                        style={imgStyle}
                                    />{" "}
                                    {swag.name}
                                </td>
                                <td>USD {priceFormat(swag.price)}</td>
                                <td>
                                    <QtySelect>
                                        <button onClick={addQty(index, -1)}>
                                            -
                                        </button>
                                        <input
                                            type="text"
                                            disabled
                                            value={swag.qty}
                                        />
                                        <button onClick={addQty(index, 1)}>
                                            +
                                        </button>
                                    </QtySelect>
                                </td>
                                <td>{priceFormat(swag.qty * swag.price)}</td>
                                <td>
                                    <Button
                                        variant="danger"
                                        onClick={deleteItem(index)}
                                    >
                                        <i className="fa fa-trash-o" />
                                    </Button>
                                </td>
                            </>
                        )}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
