import React from "react";
import ContentLoader from "react-content-loader";

const Loading = () => (
    <ContentLoader
        speed={3}
        width={1040}
        height={416}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="8" y="6" rx="7" ry="7" width="108" height="98" />
        <rect x="8" y="112" rx="5" ry="5" width="108" height="9" />
        <rect x="26" y="138" rx="5" ry="5" width="60" height="12" />
        <rect x="8" y="125" rx="5" ry="5" width="108" height="9" />
        <rect x="132" y="8" rx="7" ry="7" width="108" height="98" />
        <rect x="132" y="114" rx="5" ry="5" width="108" height="9" />
        <rect x="150" y="140" rx="5" ry="5" width="60" height="12" />
        <rect x="132" y="127" rx="5" ry="5" width="108" height="9" />
        <rect x="255" y="8" rx="7" ry="7" width="108" height="98" />
        <rect x="255" y="114" rx="5" ry="5" width="108" height="9" />
        <rect x="273" y="140" rx="5" ry="5" width="60" height="12" />
        <rect x="255" y="127" rx="5" ry="5" width="108" height="9" />
    </ContentLoader>
);

export default Loading;
