import React, { useState } from "react";
import { Container, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import priceFormat from "../utils/priceFormat";
import { Tag, StyledProductDetail, QtySelect } from "../styles/components";
import { SEO, Stars } from "./";
import CartDetail from "./CartDetail";

import * as Actions from "../store/actions/cart";

export default function ProducDetail({
    list_price,
    id,
    name,
    description,
    img,
}) {
    let history = useHistory();
    const dispatch = useDispatch();
    const formatePrice = priceFormat(list_price);
    const [qty, setQty] = useState(1);

    const handleSubmit = () => {
        dispatch(
            Actions.addRow({ productId: id, qty, price: list_price, name, img })
        );
        setShow(true);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleKeep = () => {
        setShow(false);
        history.push("/");
    };
    const handleCart = () => {
        setShow(false);
        history.push("/cart");
    };

    if (!img) img = "https://via.placeholder.com/170";

    return (
        <Container>
            <StyledProductDetail>
                <Modal show={show} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Su compra ha sido actualizada</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CartDetail modal={true} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleKeep}>
                            Seguir comprando
                        </Button>
                        <Button variant="success" onClick={handleCart}>
                            Ir al carrito
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SEO title={name} description={`${name}`} image={img} />
                <img src={img} alt={name} />
                <div>
                    <Tag>Popular</Tag>
                    <h2>{name}</h2>
                    <b>PEN {formatePrice}</b>
                    <Stars />
                    <p>Cantidad:</p>
                    <QtySelect>
                        <button
                            onClick={() => (qty > 1 ? setQty(qty - 1) : null)}
                        >
                            -
                        </button>
                        <input type="text" disabled value={qty} />
                        <button onClick={() => setQty(qty + 1)}>+</button>
                    </QtySelect>
                    <Button variant="success" onClick={handleSubmit}>
                        <i className="fa fa-cart-plus" /> Agregar al carrito
                    </Button>
                    <small>{description}</small>
                </div>
            </StyledProductDetail>
        </Container>
    );
}
