import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Breadcrumb } from "react-bootstrap";

export default function MyBreadcrumb() {
    const history = useHistory();
    const location = useLocation();
    const categories = useSelector(({ pos }) => pos.categories);
    const [selected, setSelected] = useState(-1);
    const [level, setLevel] = useState(-1);
    const [last, setLast] = useState(-1);

    const handleHome = () => {
        history.push({
            pathname: "/",
            search: "",
        });
    };

    const handleClick = ({ id, name }) => () => {
        history.push({
            pathname: "/",
            search: `?category=${id}&name=${name}`,
        });
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has("category") !== "") {
            const categoryId = parseInt(urlParams.get("category"));
            let index = categories.findIndex((it) => it.id === categoryId);
            if (index === -1) {
                let indexSec = -1;
                let indexTer = -1;
                categories.forEach((it, ind1) => {
                    if (indexSec === -1) {
                        const indexTemp = it.children.findIndex(
                            (it2) => it2.id === categoryId
                        );
                        if (indexTemp !== -1) {
                            index = ind1;
                            indexSec = indexTemp;
                        }
                    }
                });
                if (index === -1) {
                    categories.forEach((it, ind1) => {
                        if (indexSec === -1) {
                            it.children.forEach((it2, ind2) => {
                                const indexTemp = it2.children.findIndex(
                                    (it3) => it3.id === categoryId
                                );
                                if (indexTemp !== -1) {
                                    index = ind1;
                                    indexSec = ind2;
                                    indexTer = indexTemp;
                                }
                            });
                        }
                    });
                }
                setSelected(index);
                setLevel(indexSec);
                setLast(indexTer);
            } else {
                setSelected(index);
            }
        }
    }, [location.search]);

    return (
        <Container className="mt-md-2">
            <Breadcrumb>
                <Breadcrumb.Item onClick={handleHome}>Inicio</Breadcrumb.Item>
                {selected !== -1 && (
                    <>
                        <Breadcrumb.Item
                            onClick={handleClick(categories[selected])}
                        >
                            {categories[selected].name}
                        </Breadcrumb.Item>
                        {level !== -1 && (
                            <>
                                <Breadcrumb.Item
                                    onClick={handleClick(
                                        categories[selected].children[level]
                                    )}
                                >
                                    {categories[selected].children[level].name}
                                </Breadcrumb.Item>
                                {last !== -1 && (
                                    <>
                                        <Breadcrumb.Item
                                            onClick={handleClick(
                                                categories[selected].children[
                                                    level
                                                ].children[last]
                                            )}
                                        >
                                            {
                                                categories[selected].children[
                                                    level
                                                ].children[last].name
                                            }
                                        </Breadcrumb.Item>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Breadcrumb>
        </Container>
    );
}
