import axios from "../../utils/axios";

import * as actionTypes from "./actionTypes";

export const reset = () => {
    return {
        type: actionTypes.RESET,
    };
};

export const addRow = (payload) => {
    return {
        type: actionTypes.ADD_ITEM,
        payload,
    };
};

export const removeRow = (index) => {
    return {
        type: actionTypes.REMOVE_ITEM,
        index,
    };
};

export const setQty = (index, qty) => {
    return {
        type: actionTypes.UPD_QTY,
        index,
        qty,
    };
};

export const setLoading = (value) => {
    return {
        type: actionTypes.LOADING,
        value,
    };
};

export const setSuccess = (payload) => {
    return {
        type: actionTypes.SUCCESS,
        payload,
    };
};

export const saveOrder = (partner) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const items = getState().cart.items.map((it) => {
                return [
                    0,
                    0,
                    {
                        product_id: it.productId,
                        product_uom_qty: it.qty,
                        price_unit: parseFloat(it.price),
                    },
                ];
            });
            const saleOrder = {
                partner_id: partner,
                order_line: items,
            };
            const { data } = await axios.post("/api/cart", saleOrder);
            dispatch(setSuccess(data));
        } catch (err) {
            dispatch(setLoading(false));
            console.error("[save]", err);
        }
    };
};
