import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Modal, Form } from "react-bootstrap";

import axios from "../utils/axios";
import * as Actions from "../store/actions/cart";

import { StyledCart } from "../styles/components";
import CartDetail from "./CartDetail";

import priceFormat from "../utils/priceFormat";

export default function Cart() {
    const history = useHistory();
    const dispatch = useDispatch();
    const cantidad = useSelector(({ cart }) => cart.items.length);
    const total = useSelector(({ cart }) => cart.total);
    const loading = useSelector(({ cart }) => cart.loading);
    const response = useSelector(({ cart }) => cart.response);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === "" || phone === "" || name === "" || street === "") {
            return alert("Debe completar sus datos!");
        }
        dispatch(
            Actions.saveOrder({
                name,
                document,
                email,
                phone,
                street,
            })
        );
    };

    useEffect(() => {
        if (response) {
            history.push("/thanks", response);
        }
    }, [response, history]);

    const [show, setShow] = useState(false);
    const [document, setDocument] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");

    const handleClose = () => {
        setName("");
        setDocument("");
        setEmail("");
        setPhone("");
        setStreet("");
        setShow(false);
    };
    const handleShow = () => setShow(true);
    const handleEmail = (e) => setEmail(e.target.value);
    const handlePhone = (e) => setPhone(e.target.value);
    const handleStreet = (e) => setStreet(e.target.value);
    const handleDocument = (e) => {
        const documentValue = e.target.value;
        setDocument(documentValue);
        const checkData = async (documentValue) => {
            if (documentValue.length === 8 || documentValue.length === 11) {
                debounce(getData, 500, documentValue);
            }
        };
        checkData(documentValue);
    };
    const handleName = (e) => setName(e.target.value);

    let timeout;
    var debounce = function (func, delay, param) {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            func(param);
        }, delay);
    };
    const getData = async (documentValue) => {
        const { data } = await axios.post("api/ruc/" + documentValue);
        if (data) {
            if (data.nombre) setName(data.nombre);
            if (data.name) setName(data.name);
        }
    };

    return (
        <StyledCart>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ya casi terminamos...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>RUC o DNI (opcional)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese su número de contacto"
                                    value={document}
                                    onChange={handleDocument}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nombre (*)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese su nombre o razón social"
                                    value={name}
                                    onChange={handleName}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Teléfono o Celular (*)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese su número de contacto"
                                    value={phone}
                                    onChange={handlePhone}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Correo electrónico (*)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Ingrese un correo válido"
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Dirección de entrega (*)
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese su dirección de entrega"
                                    value={street}
                                    onChange={handleStreet}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {loading ? null : (
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="success" onClick={handleSubmit}>
                                <i className="fa fa-save" /> Crear pedido
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            <h2>Carrito de compras</h2>
            <CartDetail />
            <nav>
                <div>
                    <h3>Total: </h3>
                    <small>PEN {priceFormat(total)}</small>
                </div>
                <div>
                    <Link to="/">
                        <Button variant="warning">Seguir comprando</Button>
                    </Link>
                    <Button
                        variant="success"
                        onClick={handleShow}
                        disabled={cantidad === 0}
                    >
                        Comprar
                    </Button>
                </div>
            </nav>
        </StyledCart>
    );
}
