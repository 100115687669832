import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = () => (
    <ContentLoader
        speed={4}
        width={1040}
        height={416}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="8" y="6" rx="7" ry="7" width="125" height="114" />
        <rect x="146" y="12" rx="5" ry="5" width="125" height="10" />
        <rect x="167" y="42" rx="5" ry="5" width="70" height="14" />
        <rect x="146" y="27" rx="5" ry="5" width="125" height="10" />
        <rect x="141" y="68" rx="5" ry="5" width="125" height="10" />
        <rect x="162" y="99" rx="5" ry="5" width="70" height="14" />
        <rect x="141" y="84" rx="5" ry="5" width="125" height="10" />
    </ContentLoader>
);

export default MyLoader;
