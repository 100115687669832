import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    Navbar,
    Image,
    Nav,
    Form,
    FormControl,
    Button,
    Container,
    InputGroup,
} from "react-bootstrap";
import PropTypes from "prop-types";

import CategoriesMenu from "./CategoriesMenu";

import getParams from "../utils/getParams";
import useWindowSize from "../hooks/useWindowSize";

import imgLogo from "../assets/img/logo.png";
import logoWhatsapp from "../assets/img/whatsapp-lite.png";

const Header = () => {
    const windowSize = useWindowSize();
    const history = useHistory();
    const cantidad = useSelector(({ cart }) => cart.items.length);
    const [text, setText] = useState("");

    const handleText = (e) => {
        setText(e.target.value);
    };
    const handleSearch = (e) => {
        e.preventDefault();
        const params = getParams();
        params.search = text;
        if (text === "") {
            return history.push({
                pathname: "/",
                search: "",
            });
        }
        const strParams = Object.keys(params)
            .map(function (key) {
                return key + "=" + params[key];
            })
            .join("&");
        history.push({
            pathname: "/",
            search: "?" + strParams,
        });
    };
    const handleLogo = () => {
        history.push({
            pathname: "/",
            search: "",
        });
    };

    return (
        <Navbar
            //variant="dark"
            expand="lg"
            /*sticky="top"*/
        >
            <Container style={{ borderBottom: "1px solid #f0f0f0" }}>
                <Navbar.Brand>
                    <div style={{ display: "inline-block" }}>
                        <Image
                            src={imgLogo}
                            onClick={handleLogo}
                            style={{ maxWidth: "80px", cursor: "pointer" }}
                        />
                    </div>
                    <div
                        style={{ display: "inline-block", paddingLeft: "10px" }}
                    >
                        {windowSize !== "sm" ? <CategoriesMenu /> : null}
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    style={{
                        justifyContent: "flex-end",
                        verticalAlign: "middle",
                    }}
                >
                    <Form onSubmit={handleSearch} inline>
                        <InputGroup>
                            <FormControl
                                value={text}
                                onChange={handleText}
                                placeholder="Hola, ¿Que estas buscando?"
                                aria-label="Hola, ¿Que estas buscando?"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <Button variant="outline-primary">
                                    <i className="fa fa-search" />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form>
                    <Nav.Link
                        as={Link}
                        to="/cart"
                        //style={{ color: "#FFF" }}
                    >
                        <i className="fa fa-shopping-cart" /> {cantidad}{" "}
                        {windowSize === "sm" && "producto(s) - Ver mi carrito"}
                    </Nav.Link>
                    <a
                        href="https://api.whatsapp.com/send?phone=54964585354&text=Hola%20necesito%20ayuda%20para%20realizar%20mi%20pedido%20desde%20la%20pagina%20web"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link"
                        //style={{ color: "#FFF" }}
                    >
                        <Image
                            src={logoWhatsapp}
                            style={{ cursor: "pointer" }}
                        />{" "}
                        980760498
                    </a>
                    {windowSize === "sm" ? <CategoriesMenu /> : null}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
