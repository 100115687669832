import React from "react";
//import { useSelector } from "react-redux";
import {
    Container,
    Row,
    Col,
    Modal,
    Button,
    Form,
    Card,
    Popover,
    OverlayTrigger,
} from "react-bootstrap";
import styled from "styled-components";

const StyledContainer = styled.div`
    font-weight:400;
    font-size:12px;
    color: #646464;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    background-color: #fff;
`;

const PopoverHelp = (
    <Popover id="popover-basic">
        <Popover.Content>
            <h6>Mayorista</h6>
            <strong style={{ color: "#e72e36" }}>999999999</strong>
            <br />
            <h6>Mayorista</h6>
            <strong style={{ color: "#e72e36" }}>999999999</strong>
            <h4>
                <i className="fa fa-home"></i> Nuestras Tiendas
            </h4>
            <ul>
                <li>Calle dean Valdivia 1</li>
                <li>Calle dean Valdivia 1</li>
                <li>Calle dean Valdivia 1</li>
                <li>Calle dean Valdivia 1</li>
                <li>Calle dean Valdivia 1</li>
                <li>Calle dean Valdivia 1</li>
            </ul>
        </Popover.Content>
    </Popover>
);

function ModalDespatchPreference(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Preferencias de compra
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>
                    Ingresa tu correo para brindarte una mejor experiencia.
                </strong>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Ingresa tu correo electrónico</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="ej: mas_ventas@hotmail.com"
                        />
                        <Form.Text className="text-muted">
                            Ingresa una dirección de corre válida.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Acepto las políticas de uso de datos personales"
                        />
                    </Form.Group>
                    <strong>Solicitamos tu correo para:</strong>
                    <Card body>
                        <Row className="mb-5">
                            <Col className="text-center">
                                <i
                                    className="fa fa-fw fa-user"
                                    style={{ fontSize: "24px" }}
                                />
                                <br /> Identificar tu perfil.
                            </Col>
                            <Col className="text-center">
                                <i
                                    className="fa fa-fw fa-star"
                                    style={{ fontSize: "24px" }}
                                />
                                <br /> Facilitarte el proceso de compra.
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <i
                                    className="fa fa-fw fa-envelope"
                                    style={{ fontSize: "24px" }}
                                />
                                <br /> Notificarte sobre los estados de compra..
                            </Col>
                            <Col className="text-center">
                                <i
                                    className="fa fa-fw fa-star"
                                    style={{ fontSize: "24px" }}
                                />
                                <br /> Guardar el historial de compras..
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Button
                        onClick={props.onHide}
                        style={{ margin: "0 auto", display: "block" }}
                        variant="primary"
                        type="button"
                    >
                        CONTINUAR
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const SubHeader = () => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <StyledContainer>
            <Container>
                <Row>
                    <Col>
                        <a
                            href="javascript:void(0)"
                            onClick={() => setModalShow(true)} 
                        >
                            Elige tu método de entrega{" "}
                            <i className="fa fa-angle-down" />
                        </a>

                        <ModalDespatchPreference
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </Col>
                    <Col className="text-right">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={PopoverHelp}
                        >
                            <a href="javascript:void(0)">
                                ¿Necesitas ayuda? <i className="fa fa-angle-down" />
                            </a>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </Container>
        </StyledContainer>
    );
};

/*Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};*/

export default SubHeader;
