import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Jumbotron, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import * as Actions from "../store/actions/cart";

export default function Thanks() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const response = useSelector(({ cart }) => cart.response);

    useEffect(() => {
        setData(response);
        dispatch(Actions.reset());
        if (!response) {
            history.push("/");
        }
    }, []);

    if (!data) return null;

    const handleHome = () => {
        history.push("/");
    };

    return (
        <Jumbotron>
            <h1>Gracias por su compra</h1>
            <p>
                Estimado {data.partnerData.name}, su pedido {data.orderName} ya
                fue recibido. Muy pronto lo contactaremos al teléfono{" "}
                {data.partnerData.phone} o al correo {data.partnerData.email}
            </p>
            <p>
                <Button variant="primary" onClick={handleHome}>
                    Sigue viendo nuestros productos
                </Button>
            </p>
        </Jumbotron>
    );
}
