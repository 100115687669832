import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Button, Card } from "react-bootstrap";

import axios from "../utils/axios";

import Loading from "./Loading";

import formatprice from "../utils/priceFormat";
import { StyledProducts } from "../styles/components";

export default function Products() {
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [name, setName] = useState("");

    const loadData = async () => {
        try {
            const query = {
                page,
            };
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.has("search") !== "") {
                query.text = urlParams.get("search");
            }
            if (urlParams.has("category") !== "") {
                query.category = urlParams.get("category");
            }
            if (urlParams.has("name") !== "") {
                setName(urlParams.get("name"));
            }
            const { data } = await axios.post(`/api/product`, query);
            if (page > 1) setProducts([...products, ...data]);
            else setProducts(data);
        } catch (err) {
            console.error("[axios prods]", err);
        }
    };

    const handleMore = () => {
        setPage(page + 1);
    };

    const handleRefresh = () => {
        setProducts([]);
        if (page === 1) loadData();
        else setPage(1);
    };

    useEffect(() => {
        loadData();
    }, [page]);

    let location = useLocation();
    useEffect(() => {
        handleRefresh();
    }, [location.search]);

    if (products.length === 0) {
        return (
            <Container>
                <StyledProducts>
                    <section>
                        <Loading />
                    </section>
                </StyledProducts>
            </Container>
        );
    }
    return (
        <StyledProducts>
            {name !== "" && <h3>{name}</h3>}
            <section>
                {products.map((node) => {
                    const price = formatprice(node.list_price);
                    let imgUrl = node.img || "https://via.placeholder.com/170";
                    return (
                        <Card style={{ width: "15rem" }} key={node.id}>
                            <Link to={`/product/${node.id}`}>
                                <Card.Img
                                    variant="top"
                                    src={imgUrl}
                                    alt={node.name}
                                    width="220"
                                    height="220"
                                />
                            </Link>
                            <Card.Body>
                                <Card.Title
                                    style={{
                                        fontSize: "13px",
                                        color: "inherit",
                                    }}
                                >
                                    <Link to={`/product/${node.id}`}>
                                        {node.name}
                                    </Link>
                                </Card.Title>
                                <span
                                    style={{
                                        fontSize: "18px",
                                        color: "#e72e36",
                                        fontWeight: 500,
                                    }}
                                >
                                    {price}
                                </span>
                                <Card.Text></Card.Text>
                                <Link
                                    className="btn btn-primary btn-block"
                                    to={`/product/${node.id}`}
                                    variant="primary"
                                >
                                    AGREGAR
                                </Link>
                            </Card.Body>
                        </Card>
                    );
                })}
            </section>
            <div className="d-flex justify-content-center mt-5">
                <Button onClick={handleMore}>
                    <i className="fa fa-plus" /> Ver más resultados
                </Button>
            </div>
        </StyledProducts>
    );
}
