import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Layout from "./components/layout";

import Home from "./pages/Home";
import ProductDetail from "./pages/ProductDetail";
import Cart from "./pages/Cart";
import Thanks from "./pages/Thanks";
import NotFound from "./pages/404";

import { GlobalStyles } from "./styles";

import "./assets/css/search_input.css";

function App() {
    return (
        <BrowserRouter>
            <GlobalStyles />
            <Layout>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/thanks" exact component={Thanks} />
                    <Route path="/cart" exact component={Cart} />
                    <Route
                        path="/category/:productId"
                        exact
                        component={ProductDetail}
                    />
                    <Route
                        path="/product/:productId"
                        exact
                        component={ProductDetail}
                    />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
