import * as actionTypes from "../actions/actionTypes";
import updateObject from "../../utils/updateObject";

const initialState = {
    categories: [],
};

const setCategories = (state, action) => {
    const categories = [];
    const leftovers = [];
    const leftovers2 = [];
    action.payload.forEach((item) => {
        if (!item.parent_id) {
            item.children = [];
            item.index = categories.length;
            categories.push(item);
        } else {
            leftovers.push(item);
        }
    });
    leftovers.forEach((item) => {
        const index = categories.findIndex((it) => it.id === item.parent_id);
        if (index !== -1) {
            item.children = [];
            item.parent_index = categories[index].index;
            item.index = categories[index].children.length;
            categories[index].children.push(item);
        } else {
            leftovers2.push(item);
        }
    });
    let children = [];
    categories.forEach((it) => {
        children = [...children, ...it.children];
    });
    leftovers2.forEach((item) => {
        const index = children.findIndex((it) => it.id === item.parent_id);
        if (index !== -1) {
            categories[children[index].parent_index].children[
                children[index].index
            ].children.push(item);
        }
    });
    return updateObject(state, {
        categories: categories,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CATEGORIES:
            return setCategories(state, action);
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
