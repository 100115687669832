import * as actionTypes from "../actions/actionTypes";
import updateObject from "../../utils/updateObject";

const initialState = {
    items: [],
    total: 0,
    loading: false,
    response: null,
};

const addItem = (state, action) => {
    const newItems = [...state.items];
    const index = state.items.findIndex(
        (it) => it.productId === action.payload.productId
    );
    if (index !== -1) {
        newItems[index].qty += action.payload.qty;
    } else {
        newItems.push(action.payload);
    }
    const total = calcTotal(newItems);
    return updateObject(state, { total, items: newItems });
};

const removeItem = (state, action) => {
    const newItems = state.items.filter((it, i) => i !== action.index);
    const total = calcTotal(newItems);
    return updateObject(state, { total, items: newItems });
};

const setQty = (state, action) => {
    const newItems = state.items.map((item, index) => {
        if (action.index === index) {
            item.qty = action.qty;
        }
        return item;
    });
    const total = calcTotal(newItems);
    return updateObject(state, { total, items: newItems });
};

const calcTotal = (items) => {
    return items.reduce((acc, current) => acc + current.price * current.qty, 0);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_ITEM:
            return addItem(state, action);
        case actionTypes.REMOVE_ITEM:
            return removeItem(state, action);
        case actionTypes.UPD_QTY:
            return setQty(state, action);
        case actionTypes.LOADING:
            return updateObject(state, { loading: action.value });
        case actionTypes.SUCCESS:
            return updateObject(state, {
                loading: true,
                response: action.payload,
            });
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
