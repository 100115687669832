import React from "react";
import { Image } from "react-bootstrap";

import { Footer as StyledFooter } from "../styles/components";

import imgLogo from "../assets/img/logo.png";

export default function Footer() {
    return (
        <StyledFooter>
            <div className="footer_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 footer_col">
                            <div
                                className="footer_logo"
                                style={{ textAlign: "center" }}
                            >
                                <Image
                                    src={imgLogo}
                                    thumbnail
                                    style={{ maxWidth: "150px" }}
                                />
                            </div>
                        </div>

                        <div className="col-lg-5 footer_col">
                            <p>
                                Más Ventas es una empresa arequipeña con más de
                                26 años de experiencia, trabajando para surtir
                                con productos de calidad a la región.
                            </p>
                            <strong>Síguenos en: </strong>
                            <a
                                href="https://www.facebook.com/arequipamasventas"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa fa-facebook"></i>
                            </a>{" "}
                            <a
                                href="https://www.instagram.com/masventasaqp/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>

                        <div className="col-lg-4 footer_contact">
                            <h4>Comunícate con nosotros</h4>
                            <ul className="footer_locales">
                                <li>Cercado: 054-XXXXXX</li>
                                <li>Cayma: 054-XXXXXX</li>
                                <li>Parque Industrial: 054-XXXXXX</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bar">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="footer_bar_content d-flex flex-md-row flex-column align-items-center justify-content-center">
                                <div className="copyright order-md-1 order-2">
                                    Copyright ©2020 Hecho con{" "}
                                    <i
                                        className="fa fa-heart-o"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    por{" "}
                                    <a
                                        href="https://conflux.pe"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Conflux
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFooter>
    );
}
