import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import * as Actions from "../store/actions/pos";

import { Content } from "../styles/components";

import Header from "./header";
import SubHeader from "./SubHeader";
import Footer from "./footer";

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Actions.loadData());
    }, []);
    return (
        <>
            <Header />
            <SubHeader />
            <Content>
                <main>{children}</main>
                <Footer />
            </Content>
        </>
    );
};
Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
