import axios from "../../utils/axios";

import * as actionTypes from "./actionTypes";

export const reset = () => {
    return {
        type: actionTypes.RESET,
    };
};

export const setCategories = (payload) => {
    return {
        type: actionTypes.SET_CATEGORIES,
        payload,
    };
};

export const loadData = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.post("/api/categories");
            dispatch(setCategories(data));
        } catch (err) {
            console.error("[get_pos]", err);
        }
    };
};
