import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Carousel } from "react-bootstrap";
/*import { StyledJumbo } from "../styles/components";
import { Image } from "./";*/

import Breadcrumb from "./Breadcrumb";

import imgSlide1 from "../assets/img/slide-1.jpg";
import imgSlide2 from "../assets/img/slide-2.jpg";
import imgSlide3 from "../assets/img/slide-3.jpg";

const styleItem = {
    maxHeight: "720px",
};

export default function Jumbo() {
    const [show, setShow] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.search === "") {
            return setShow(true);
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let check = false;
        if (urlParams.has("search") !== "") {
            check = true;
        }
        if (urlParams.has("category") !== "") {
            check = true;
        }
        if (check) setShow(false);
        else setShow(true);
    }, [location.search]);

    if (!show) {
        return <Breadcrumb />;
    }

    return (
        <Carousel>
            <Carousel.Item style={styleItem}>
                <img
                    className="d-block w-100"
                    src={imgSlide1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item style={styleItem}>
                <img
                    className="d-block w-100"
                    src={imgSlide2}
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item style={styleItem}>
                <img
                    className="d-block w-100"
                    src={imgSlide3}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    );
    /*return (
        <StyledJumbo>
            <div>
                <h2>¡Consigue el mejor swag exclusivo y especial de Platzi!</h2>
                <small>{description}</small>
            </div>
            <Image name="icon" />
        </StyledJumbo>
    );*/
}
