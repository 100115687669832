import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactMegaMenu from "react-mega-menu";

import Categories from "./Categories";
import useWindowSize from "../hooks/useWindowSize";

const darkRed = "#e72e36";

const CategoriesMenu = () => {
    const windowSize = useWindowSize();
    const history = useHistory();
    const categories = useSelector(({ pos }) => pos.categories);
    const handleClick = ({ id, name }) => () => {
        history.push({
            pathname: "/",
            search: `?category=${id}&name=${name}`,
        });
    };
    if (windowSize === "sm") {
        return <Categories />;
    }
    return (
        <Dropdown>
            <Dropdown.Toggle as="span" style={{ cursor: "pointer" }}>
                Categorías
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ border: "0" }}>
                <ReactMegaMenu
                    data={categories.map((it) => {
                        return {
                            label: it.name,
                            key: it.id,
                            items: (
                                <div class="row">
                                    {it.children.map((it2) => {
                                        return (
                                            <div key={it2.id} class="col-md-4">
                                                <h3
                                                    style={{
                                                        borderBottom: darkRed,
                                                        whiteSspace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }}
                                                >
                                                    <a
                                                        href="javascript:void(0);"
                                                        style={{
                                                            fontSize: "15px",
                                                        }}
                                                        onClick={handleClick(
                                                            it2
                                                        )}
                                                    >
                                                        {it2.name}
                                                    </a>
                                                </h3>
                                                <ul
                                                    style={{
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                >
                                                    {it2.children.map((it3) => (
                                                        <li
                                                            style={{
                                                                padding:
                                                                    "0 0 7px 7px",
                                                                whiteSspace:
                                                                    "nowrap",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                            key={it3.id}
                                                        >
                                                            <a
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight: 400,
                                                                    color:
                                                                        "#606060",
                                                                }}
                                                                href="javascript:void(0)"
                                                                onClick={handleClick(
                                                                    it3
                                                                )}
                                                            >
                                                                {it3.name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </div>
                            ),
                        };
                    })}
                    styleConfig={{
                        menuProps: {
                            style: {
                                width: "16em",
                                padding: "2px",
                                margin: "0",
                                borderRight: "1px solid #CCCCCC",
                            },
                        },
                        contentProps: {
                            style: {
                                width: "45em",
                                backgroundColor: "#FFFFFF",
                                padding: "5px 0 0 30px",
                            },
                        },
                        menuItemProps: {
                            style: {
                                whiteSspace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "6px 0 6px 6px",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#606060",
                            },
                        },
                        menuItemSelectedProps: {
                            style: {
                                whiteSspace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "6px 0 6px 6px",
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#bc0d0d",
                            },
                        },
                        containerProps: {
                            style: {
                                padding: "2px",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #CCCCCC",
                            },
                        },
                    }}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CategoriesMenu;
