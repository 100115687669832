import React from "react";
import { Container } from "react-bootstrap";

import { Jumbo } from "../components";
import Categories from "../components/Categories";
import Products from "../components/Products";

import useWindowSize from "../hooks/useWindowSize";

export default function Home() {
    const windowSize = useWindowSize();
    return (
        <>
            <Jumbo description="Ecommerce de mas ventas" />
            {windowSize === "md" || windowSize === "sm" ? (
                <Container>
                    <Products />
                </Container>
            ) : (
                <div style={{backgroundColor:"#f6f6f6"}}>
                    <Container>
                        <div className="row">
                            <div className="col-3">
                                <Categories />
                            </div>
                            <div className="col-9">
                                <Products />
                            </div>
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
}
