import React, { useState, useEffect } from "react";

import axios from "../utils/axios";

import { ProductDetail } from "../components";
import { StyledProductDetail } from "../styles/components";

import LoadingProduct from "../components/LoadingProduct";

export default function Detail({
    match: {
        params: { productId },
    },
}) {
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const { data } = await axios.post(`/api/product/${productId}`);
                setProduct(data);
            } catch (err) {
                console.error("[axios prod]", err);
            }
        };
        loadData();
    }, [productId]);

    if (!product) {
        return (
            <StyledProductDetail>
                <LoadingProduct />
            </StyledProductDetail>
        );
    }
    return <ProductDetail {...product} />;
}
